import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import MainSlider from '../components/slider';
import { Container } from '../components/styled';

const IndexPage = ({ data }) => (
  <Layout>
    <MainSlider>
      {data.markdownRemark.frontmatter.images.map(image => (
        <Img fluid={image.childImageSharp.fluid} />
      ))}
    </MainSlider>
    <Container>
      {data.markdownRemark.frontmatter.seasonal && (
        <div
          style={{
            border: '2px #c69300 solid',
            borderRadius: '4px',
            padding: '2% 0%',
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          {data.markdownRemark.frontmatter.seasonal}
        </div>
      )}
      <h1>{data.markdownRemark.frontmatter.title}</h1>
      <p dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Container>
  </Layout>
);

export const query = graphql`
  query IndexPageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seasonal
        title
        images {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { arrayOf, element, oneOfType } from 'prop-types';

const SliderContainer = styled.div`
  max-width: 100%;
  @media (max-width: 780px) {
    display: none;
  }
`;

const MainSlider = ({ children }) => (
  <SliderContainer>
    <Slider autoplay autoplaySpeed={5000} fade>
      {children}
    </Slider>
  </SliderContainer>
);

MainSlider.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

MainSlider.defaultProps = {
  children: [],
};

export default MainSlider;
